import { createTheme, ThemeProvider } from '@mui/material/styles';


const PRIMARY_COLOR = "#334D7C";
const PRIMARY_COLOR_600 = "#001D53";
const PRIMARY_COLOR_300 = "#546A91";
export const theme = createTheme({
    components: {
      MuiAppBar: {
        styleOverrides: {
          root: {
            backgroundColor: 'white',
            color: "#2E3B55"
          },
        },
      },
    },
    palette: {
      airbus: {
        main: PRIMARY_COLOR,
        light: PRIMARY_COLOR_300,
        dark: PRIMARY_COLOR_600,
        contrastText: '#ffffff',
      },
      airbusError: {
        main: "#B22D26",
        light: "#CF564F",
        dark: "#8A2B26",
        contrastText: '#ffffff',
      },
    },
  })