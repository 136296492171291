import React, { useEffect, useState } from 'react';
import { BrowserRouter as Router, Routes, Route, Navigate, useParams } from 'react-router-dom';
import LandingPage from './pages/LandingPage';
import ProviderSelectionPage from './pages/ProviderSelectionPage';
import { signInWithRedirect, getCurrentUser, fetchAuthSession } from 'aws-amplify/auth';
import '@aws-amplify/ui-react/styles.css';

const providers = {
  corpsso: { custom: 'SamlProviderCorpSSO-prod' },
  mobilesso: { custom: 'SamlProviderMobileSSO-prod' }
};

const AuthHandler = ({ handleAuth }) => {
  const { providerKey } = useParams();
  
  useEffect(() => {
    handleAuth(providerKey);
  }, [providerKey, handleAuth]);

  return <div>Authenticating...</div>;
};

const App = () => {
  const [isAuthenticated, setIsAuthenticated] = useState(false);
  const [isLoading, setIsLoading] = useState(true);
  const [fullName, setFullName] = useState('');
  const [email, setEmail] = useState('');

  useEffect(() => {
    checkUserAuthentication();
  }, []);

  const getUserFullName = async () => {
    try {
      const user = await getCurrentUser();
      const idToken = (await fetchAuthSession()).tokens.idToken;
      const payload = idToken.payload;
      console.log(payload);
      const firstName = payload['custom:firstname']; //TODO: CORRECT THE ATTRIBUTES
      const lastName = payload['custom:lastname']; //TODO: CORRECT THE ATTRIBUTES
      setEmail(payload['email']);
      return `${firstName} ${lastName}`;
    } catch (error) {
      console.error('Error getting user full name:', error);
      return '';
    }
  };

  const checkUserAuthentication = async () => {
    try {
      const user = await getCurrentUser();
      if (user) {
        setIsAuthenticated(true);
        fetchUserFullName();
      }
    } catch (error) {
      console.log("User not signed in yet");
    } finally {
      setIsLoading(false);
    }
  };

  const fetchUserFullName = async () => {
    const name = await getUserFullName();
    setFullName(name);
  };

  const handleAuth = (providerKey) => {
    signInWithRedirect({ provider: providers[providerKey] });
  };

  if (isLoading) {
    return <div>Loading...</div>;
  }

  return (
    <Router>
      <Routes>
        <Route path="/" element={isAuthenticated ? <LandingPage fullName={fullName} email={email} /> : <Navigate to="/select-provider" />} />
        <Route path="/select-provider" element={<ProviderSelectionPage />} />
        <Route path="/auth/:providerKey" element={<AuthHandler handleAuth={handleAuth} />} />
      </Routes>
    </Router>
  );
};

export default App;