import cdkOutputs from './cdk-output.json';

const STACK_NAME="LicenseCheckerStack";
const awsConfig = {
  Auth: {
    Cognito: {
      userPoolId: cdkOutputs[STACK_NAME].UserPoolId,
      userPoolClientId: cdkOutputs[STACK_NAME].UserPoolClientId,
      mandatorySignIn: true,
      authentificationFlowType: 'USER_SRP_AUTH',
      signUpVerificationMethod: 'code',
      loginWith: {
        oauth: {
          domain: cdkOutputs[STACK_NAME].CognitoDomain,
          scopes: [
            'phone',
            'email',
            'profile',
            'openid',
            'aws.cognito.signin.user.admin',
          ],
          redirectSignIn: ["https://"+cdkOutputs[STACK_NAME].CloudFrontURL+"/","http://localhost:3000/",'https://oneloginlicense.airbus.com/'],
          redirectSignOut: ["https://"+cdkOutputs[STACK_NAME].CloudFrontURL+"/","http://localhost:3000/","https://oneloginlicense.airbus.com/"],
          responseType: 'code',
        },
      },
    },
  },
  API: {
    GraphQL: {
      endpoint: cdkOutputs[STACK_NAME].AppSyncEndpoint,
      region: cdkOutputs[STACK_NAME].AppSyncRegion,
      defaultAuthMode: 'userPool',
    },
  },
};

export default awsConfig;