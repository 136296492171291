import moment from 'moment';
import { TextField, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Chip } from '@mui/material';

const MAX_DAYS=90;

export const isDateProblematic = (lastLogin, secondDate = null) => {
  const lastLoginDate = moment(lastLogin);
  
  let referenceDate;
  if (secondDate) {
    referenceDate = moment(secondDate);
  } else {
    referenceDate = moment();
  }
  
  const threeMonthsBeforeReferenceDate = referenceDate.subtract(MAX_DAYS, 'days');
  
  return lastLoginDate.isBefore(threeMonthsBeforeReferenceDate) ? true : false;
};

  export const getStatusChipProps = (status, lastLogin) => {
    if (!status) {
      return { label: 'deactivated', color: 'default', variant: 'outlined' };
    }
    const lastLoginDate = moment(lastLogin);
    const threeMonthsAgo = moment().subtract(3, 'months');
    const color = lastLoginDate.isBefore(threeMonthsAgo) ? 'error' : 'success';
    return { label: 'active', color: color, variant: 'filled' };
  };
export function getNumberOfDaysPastExpiration(last_login, secondDate = null) {
  const lastLoginDate = moment(last_login);
  
  let referenceDate;
  if (secondDate) {
    referenceDate = moment(secondDate);
  } else {
    referenceDate = moment();
  }
  
  return lastLoginDate.diff(referenceDate, 'days');
}

export function stringAvatar(name) {
    return {
      sx: {
        bgcolor: stringToColor(name),
      },
      children: `${name.split(' ')[0][0]}${name.split(' ')[1][0]}`,
    };
  }

  function stringToColor(string) {
    let hash = 0;
    let i;
  
    /* eslint-disable no-bitwise */
    for (i = 0; i < string.length; i += 1) {
      hash = string.charCodeAt(i) + ((hash << 5) - hash);
    }
  
    let color = '#';
  
    for (i = 0; i < 3; i += 1) {
      const value = (hash >> (i * 8)) & 0xff;
      color += `00${value.toString(16)}`.slice(-2);
    }
    /* eslint-enable no-bitwise */
  
    return color;
  }
  
  export async function processCSV(userIds,clientGraphQL) {
    let processCSVQuery = { 
      query : `
        mutation ProcessCSV($valarray: [Int]!){
          processCSV(input:$valarray) {
            code
          }
        }
      `,
      variables:{
        valarray:userIds
      },
      authMode: 'userPool'
    };
    try {
      const result = await clientGraphQL.graphql(processCSVQuery);
      console.log('mutation result:', result);
    } catch (error) {
      console.error('Error calling query:', error);
    } 
  }